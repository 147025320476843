import React from "react";
import "./main.css";
import VanillaTilt from "vanilla-tilt";
import CircularProgress from "./CircularProgress";

import { BrowserRouter, Routes, Route } from "react-router-dom";
// import "https://widgets.superpeer.com/widget.js";
import { IonIcon } from "@ionic/react";
import {
  logoGithub,
  logoTwitter,
  logoInstagram,
  mailOutline,
} from "ionicons/icons";
import FirstSectionImage from "./FirstSectionImage.png";
import MagepheShoes from "./MagepheShoes.png";
import MagepheWSC from "./MagepheWSC.png";
import MagepheMain from "./MagepheMain.png";
import AirtableList from "./Airtable";
import Projects from "./Projects";
console.log(
  "%cHi, What r u doing here?",
  "font-size: 30px; color: white; font-family: 'Segoe UI'; letter-spacing: 10px; background-color:#414187; padding: 10px 20px; border-radius: 700px;"
);
function App() {
  VanillaTilt.init(document.querySelectorAll(".container"), {
    max: 20,
    speed: 400,
  });

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/bedeveloper" element={<BeDeveloper />} />
      </Routes>
    </BrowserRouter>
  );
}
const MainPage = () => {
  const scrollPage = () => {
    window.scroll(0, 700);
  };
  const goToGithub = () => {
    window.location.href = "https://github.com/gaaziyle";
  };
  const goToInstagram = () => {
    window.location.href = "https://instagram.com/gaziinreality";
  };
  const goToTwitter = () => {
    window.location.href = "https://twitter.com/gaaziyle";
  };
  return (
    <div className="App">
      <div className="line line1"></div>
      <div className="line line2"></div>
      <div className="line line3"></div>
      <div className="line line4"></div>
      <div className="line line5"></div>
      <div className="line line6"></div>
      <div className="line line7"></div>
      <div className="line line8"></div>
      <div className="line line9"></div>
      <div className="line line10"></div>
      <div className="line line11"></div>
      <div className="line line12"></div>
      <div className="line line13"></div>
      <div className="line line14"></div>

      <div className="box">
        <div className="box__face box__face--back"></div>
        <div className="box__face box__face--front"></div>
        <div className="box__face box__face--left"></div>
        <div className="box__face box__face--right"></div>
        <div className="box__face box__face--top"></div>
        <div className="box__face box__face--bottom"></div>
      </div>

      <a id="scroll-btn" onClick={scrollPage}></a>
      <div className="social-media" id="social-media">
        <div className="sosoci">
          <a id="github-a" onClick={goToGithub} target="_blank">
            <IonIcon icon={logoGithub} />
          </a>
          <hr />
          <a id="twitter-a" onClick={goToTwitter} target="_blank">
            <IonIcon icon={logoTwitter} />
          </a>
          <hr />

          <a id="instagram-a" onClick={goToInstagram} target="_blank">
            <IonIcon icon={logoInstagram} />
          </a>
        </div>
      </div>
      <div className="contact-me" id="contact-me">
        <a className="contact-me-a" href="mailto:hi@iamgaazi.com">
          <IonIcon icon={mailOutline} id="icon-mail" />
        </a>
      </div>

      <section id="section-1">
        <div className="content-1">
          <img src={FirstSectionImage} alt="Muhammet Gazi" />
          <div className="texts">
            <h2 className="fornow-h2">Hi, I'm Muhammet Gazi</h2>
            <p className="fornow">Web Developer/Designer</p>
          </div>
        </div>
      </section>
      <section id="section-2">
        <section className="point point1"></section>

        <section className="point point2"></section>
        <section className="point point3"></section>
        <section className="point point4"></section>
        <section className="point point5"></section>
        <section className="point point6"></section>
        <section className="point point7"></section>
        <section className="point point8"></section>
        <section className="point point9"></section>
        <section className="point point10"></section>
        <section className="point point11"></section>
        <section className="point point12"></section>
        <section className="point point13"></section>
        <section className="point point14"></section>
        <div className="about-me">
          <p className="about-me-p">
            Hello, welcome! Let me briefly introduce myself. I am entering my
            fourth year in web development. I have the ability to complete
            projects both through coding and on various platforms. I mainly
            focus on both frontend and backend development, depending on the
            project's needs. You can find more information about the platforms
            and tools I use as you scroll down the page. I enjoy involving
            people in the virtual world and am passionate about this field.
          </p>
          <h1 className="about-me-h2">About Me</h1>
        </div>
      </section>
      <h2 id="skills">Skills</h2>

      <div className="cart">
        <CircularProgress
          className="container"
          progressClass="circular-progress"
          progressValueClass="progress-value"
          endValue={85}
          color="#264de4"
          delay={1000}
          text="CSS"
        />
        <CircularProgress
          className="container"
          progressClass="circular-progress-2"
          progressValueClass="progress-value-2"
          endValue={60}
          color="#61DBFB"
          delay={2000}
          text="React"
        />
        <CircularProgress
          className="container"
          progressClass="circular-progress-3"
          progressValueClass="progress-value-3"
          endValue={67}
          color="#f7df1e"
          delay={3000}
          text="Javascript"
        />
        <CircularProgress
          className="container"
          progressClass="circular-progress-4"
          progressValueClass="progress-value-4"
          endValue={70}
          color="#90B945"
          delay={4000}
          text="Shopify"
        />
        <CircularProgress
          className="container"
          progressClass="circular-progress-5"
          progressValueClass="progress-value-5"
          endValue={62}
          color="#1b769c"
          delay={5000}
          text="Wordpress"
        />
        <CircularProgress
          className="container"
          progressClass="circular-progress-6"
          progressValueClass="progress-value-6"
          endValue={88}
          color="#08089b"
          delay={6000}
          text="Shopify Liquid"
        />
      </div>

      <div className="tools" id="tools">
        <h1>Tools</h1>
        {/* This place is filled with airtable */}
        <AirtableList />
      </div>

      <section id="section-3">
        <h1>My Projects</h1>
      </section>
      <div className="general-container" id="general-container">
        {/* This place is filled with airtable */}
        <Projects />
      </div>
      <div className="demo_project">
        <h2 className="demo_project_h2_first">Demo Projects</h2>
        <div className="display_flex">
          <section className="inner_demo_project">
            <div className="project_div_demo">
              <img
                src={MagepheShoes}
                alt="magephe-shoes"
                className="project-img"
              />
              <div className="desc">
                <h2 className="project-h2">Magephe Shoes</h2>

                <button className="visit-site-off">With you soon...</button>
              </div>
            </div>
          </section>
          <section className="inner_demo_project_2">
            <div className="project_div_demo">
              <img
                src={MagepheWSC}
                alt="magephe-shoes"
                className="project-img"
              />
              <div className="desc">
                <h2 className="project-h2">Magephe Website Creator</h2>

                <button className="visit-site-off">With you soon...</button>
              </div>
            </div>
          </section>
        </div>
        <div className="display_flex">
          <section className="inner_demo_project_3">
            <div className="project_div_demo">
              <img
                src={MagepheMain}
                alt="magephe-todolist"
                className="project-img"
              />
              <div className="desc">
                <h2 className="project-h2">Magephe Todolist</h2>

                <button className="visit-site-off">With you soon...</button>
              </div>
            </div>
          </section>
          <section className="inner_demo_project_4">
            <div className="project_div_demo">
              <img
                src={MagepheMain}
                alt="magephe-reminders"
                className="project-img"
              />
              <div className="desc">
                <h2 className="project-h2">Magephe Reminders</h2>

                <button className="visit-site-off">With you soon...</button>
              </div>
            </div>
          </section>
        </div>
      </div>

      <div className="i-dont">
        <p>Contact me to learn more about me </p>
      </div>
      <footer>
        <p>
          &copy; Muhammet Gazi{" "}
          <span className="cedeny-a" id="footer_magephe">
            magephe.com
          </span>{" "}
        </p>
      </footer>
    </div>
  );
};
const BeDeveloper = () => {
  const backToHome = () => {
    window.location.href = "/";
  };
  return (
    <div>
      <div class="back_home">
        <button id="back_home_btn" onClick={backToHome}>
          Back Home
        </button>
      </div>
    </div>
  );
};
export default App;
