// src/AirtableProjects.js
import React, { useEffect, useState } from "react";
import Airtable from "airtable";
import { IonIcon } from "@ionic/react";
import { openOutline } from "ionicons/icons";
const BASE_ID = "appNZdOh0AfVNt4ki";
const PERSONAL_ACCESS_TOKEN =
  "patqjW2ZQrSj1yfeY.73aa613f357bc7c2b73cde375b38098535026cb60acc603011ef01db268285d2"; // Bu alanı kendi token'ınızla değiştirin

const AirtableProjects = () => {
  const [records, setRecords] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRecords = async () => {
      const base = new Airtable({ apiKey: PERSONAL_ACCESS_TOKEN }).base(
        BASE_ID
      );

      try {
        const recordsArray = [];
        await base("Table 2")
          .select()
          .eachPage((records, fetchNextPage) => {
            records.forEach((record) => {
              recordsArray.push(record);
            });
            fetchNextPage();
          });
        setRecords(recordsArray);
      } catch (error) {
        setError(error.message);
        console.error("Error fetching records from Airtable:", error);
      }
    };

    fetchRecords();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div id="general-container">
      <div className="inner_general_container">
        {records.map((record) => (
          <section key={record.id} className="project">
            <div className="project-div">
              <img
                className={`project-img ${record.fields.custom_class}`}
                src={record.fields.img_url}
                alt="iamgaazi_project_images"
              />
              <div className="desc">
                <h2 className="project-h2">{record.fields.project_name}</h2>
                <p className={`project-p ${record.fields.custom_class_p}`}>
                  {record.fields.p}
                </p>
                <a
                  href={record.fields.button_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="visit-site">
                    {record.fields.button}
                    <IonIcon icon={openOutline} />
                  </button>
                </a>
              </div>
            </div>
          </section>
        ))}
      </div>
    </div>
  );
};

export default AirtableProjects;
